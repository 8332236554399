import {
    VuexModule,
    Module,
    Mutation,
    Action,
    config,
    getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { components } from '@/api/models/Epinium';
import { UsersBackend } from '@/api/users/users';

config.rawError = true;

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: 'user',
})
class UserStore extends VuexModule {
    public userData: components['schemas']['LoginResponseDTO'] | null = null;

    @Mutation
    setUser(user: components['schemas']['LoginResponseDTO']) {
        this.userData = user;
    }

    @Action({ commit: 'setUser' })
    async setUserInfo(force = false) {
        const clientData =
            force || !this.userData
                ? await new UsersBackend().getUserBasicInfo()
                : this.userData;
        this.setTawkUser(clientData);
        return clientData;
    }

    @Action({})
    setTawkUser(user: components['schemas']['LoginResponseDTO'] | null) {
        if (user && window.$_Tawk.loaded) {
            const params = {
                name: user.name,
                email: user.email,
                hash: user.tawkUserHash,
                userID: user.id,
                houstonUrl: `https://houston.epinium.com/clients/${user.client._id}`,
            };
            window.Tawk_API.setAttributes(params, (error) => {
                if (error) console.log('setAttributes error', error);
            });
        }
    }

    @Action({})
    tagManagerEvent(event: string, data?: any) {
        if (!window.dataLayer) return;
        window.dataLayer.push({
            event,
            ...data,
        });
    }
}

export default getModule(UserStore);
